import React from 'react';

const DataTryoutHeader = () => {
    return (
        <thead>
             <tr className='bg-white hover:bg-gray-100 cursor-pointer'>
                <th scope="col" className="w-[5%] px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-bold text-gray-700 uppercase tracking-wider">
                    No
                </th>
                <th scope="col" className="w-[40%] px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-bold text-gray-700 uppercase tracking-wider">
                    Nama Tryout
                </th>
                <th scope="col" className="w-[30%] px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-bold text-gray-700 uppercase tracking-wider">
                    Jadwal
                </th>
                <th scope="col" className="w-[25%] px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-bold text-gray-700 uppercase tracking-wider">
                    Nilai
                </th>
            </tr>
        </thead>
    )
}

export default DataTryoutHeader;