// APP
export const INIT_SIDEBAR = "init_sidebar";
// COMMON
export const FETCH_START = 'fetch_start';   
export const FETCH_SUCCESS = 'fetch_success';   
export const FETCH_ERROR = 'fetch_error';   

// DATA PENGGUNA
export const GET_DATA_PENGGUNA = 'get_data_pengguna';
export const GET_DETAIL_PENGGUNA = 'get_detail_pengguna';
export const GET_TRYOUT_USER = 'get_tryout_user';
export const CLEAR_DETAIL_PENGGUNA ='clear_detail_pengguna';
export const CLEAR_TRYOUT_USER = 'clear_tryout-user';

// MATERI TRYOUT
export const GET_LIST_MATERI = 'get_list_materi';
export const GET_DETAIL_MATERI = 'get_detail_materi';

// PAKET TRYOUT
export const GET_LIST_PAKET = 'get_list_paket';
export const GET_DETAIL_PAKET = 'get_detail_paket';
export const CLEAR_DETAIL_PAKET = 'clear_detail_paket';

// LIST TRYOUT
export const GET_LIST_TRYOUT = 'get_list_tryout';
export const GET_DETAIL_TRYOUT = 'get_detail_tryout';
export const ADD_TRYOUT = 'add_tryout';
export const UPDATE_TRYOUT = 'update_tryout';
export const DELETE_TRYOUT = 'delete_tryout';
export const ADD_SOAL = 'add_soal';
export const UPDATE_SOAL = 'update_soal';
export const GET_DETAIL_SOAL = 'get_detail_soal';
export const DELETE_DETAIL_TRYOUT = 'delete_detail_tryout';
export const CLEAR_LIST_TRYOUT = 'clear_list_tryout';
export const CLEAR_DETAIL_TRYOUT = 'clear_detail_tryout';
export const CLEAR_DETAIL_SOAL = 'clear_detail_soal';
export const UPLOAD_FILE = 'upload_file';

// PEMBELIAN
export const GET_LIST_PEMBELIAN = 'get_list_pembelian';